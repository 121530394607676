import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f9f9f9",
    display: "flex",
    padding: "45px 50px",
    alignItems: "center",
    justifyContent: "center",
    "& .content": {
      color: "#404040",
      maxWidth: "800px !important",
      "& h2": {
        textAlign: "center"
      },
      "& p": {
        textAlign: "center !important",
        lineHeight: 1.8
      }
    }
  },
  "@media (max-width:1050px)": {}
}));
