import React, { useState } from "react";
import CustomField from "./field";
import CustomButton from "./button";
import CREATE_REGISTER from "../mutation/register";
import { useMutation } from "@apollo/react-hooks";
import { useStyles } from "../styles/welcome";

const Welcome = props => {
  const classes = useStyles();
  const [email, enterEmail] = useState("");
  const [submit, onSubmit] = useState(false);
  const [createEnquiry] = useMutation(CREATE_REGISTER);

  const submitForm = email => {
    createEnquiry({
      variables: { email }
    });
    onSubmit(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <div className="left">
          <div className="titlecontainer">
            <h1>Take control of your classroom.</h1>
            <h3>We make childcare centre reporting easy.</h3>
            <CustomField onChange={enterEmail} />
            <CustomButton
              text="Register"
              onClick={() => submitForm(email)}
              disable={submit}
            />
          </div>
        </div>
        <div className="right">
          <img src="/images/landing.png" alt="Landing" />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
