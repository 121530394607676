import React from "react";
import Welcome from "./components/welcome";
import "./App.css";
import Section from "./components/section";
import FullSection from "./components/fullsection";
import BannerSection from "./components/bannersection";
import ContactForm from "./components/contactform";
import { useStyles } from "./styles/app";

function App() {
  const classes = useStyles();
  const mobileSectionData = (
    <div className="content">
      <h2>Small footprint, high impact.</h2>
      <p>
        Both teachers and parents can sign in and sign out using their mobile
        devices, which means that you might not even need to buy any equipment
        to start using our software.
      </p>
      <p>
        Signing in digitally means you can generate reports automatically, and
        also communicate with parents more effectively and efficiently, ensuring
        messages are delivered in a timely and consistent manner.
      </p>
    </div>
  );
  const mobileDataImage = (
    <img src="/images/iphonedisplaysmall.png" alt="iPhone" />
  );
  const IntroSectionData = (
    <div className="contentLeft">
      <h2>Make data driven decisions.</h2>
      <p>
        The business side of your centre should be as clear as possible so that
        you can make decisions on purchases and hiring the best educators.
      </p>
      <p>
        We can show you how much you're spending on running your centre, and how
        much you're making so you know exactly how much money you're working
        with.
      </p>
    </div>
  );
  const IntroSectionImage = (
    <img src="/images/charts.jpg" alt="Charts" className={classes.charts} />
  );
  return (
    <div className={classes.root}>
      <Welcome />
      <BannerSection />
      <FullSection
        type={"left"}
        data={mobileSectionData}
        image={mobileDataImage}
        overridesOther={classes.styleRight}
      />
      <Section />
      <FullSection
        type={"right"}
        data={IntroSectionData}
        image={IntroSectionImage}
        overrides={classes.styleRight}
      />
      <ContactForm />
    </div>
  );
}

export default App;
