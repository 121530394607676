import React from "react";
import { useStyles } from "../styles/sectionItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SectionItem = props => {
  const classes = useStyles();
  const { title, description, icon } = props;
  return (
    <div className={classes.root}>
      <div className="iconContainer">
        <FontAwesomeIcon icon={icon} className="icon" />
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default SectionItem;
