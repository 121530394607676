import React from "react";
import SectionItem from "./sectionItem";
import { useStyles } from "../styles/section";
import {
  faPercentage,
  faSignInAlt,
  faPoll
} from "@fortawesome/free-solid-svg-icons";

const Section = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SectionItem
        title="Reporting on Ratios"
        description="Have the peace of mind of being able to report on your ratios without hours of paperwork. Teacher to student ratios reports are automatically generated based on attendance records."
        icon={faPercentage}
      />
      <SectionItem
        title="Streamlined Attendance"
        description="Know where everyone is, effortlessly. Forget about marking the roll and having to consolidate attendance records. Teachers and parents can easily sign in and sign out from their mobile devices."
        icon={faSignInAlt}
      />
      <SectionItem
        title="Centre Insights"
        description="Use insights to make data driven decisions. When you know your centre's trends, you can use this information to more accurately plan resources, which improves efficiency and profitability. "
        icon={faPoll}
      />
    </div>
  );
};

export default Section;
