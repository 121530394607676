import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    padding: "65px 50px",
    "& .left": {
      minWidth: "50%"
    },
    "& .right": {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      minWidth: "50%",
      color: "#404040"
    }
  },
  "@media (max-width:900px)": {
    root: {
      padding: "35px 50px",
      flexWrap: "wrap",
      justifyContent: "center"
    }
  }
}));
