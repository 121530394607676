import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    margin: 10,
    marginLeft: 0,
    marginRight: 0
  },
  myInput: {
    border: "1px solid #bfe8ee",
    backgroundColor: "white",
    // borderRadius: 5,
    padding: 5,
    width: 280,
    fontSize: 12
  },
  formLabel: {
    color: "#333"
  },
  formLabelFocused: {
    color: "#7368CD !important"
  }
}));

const CustomField = props => {
  const classes = useStyles();
  const {
    className,
    required,
    label,
    multiline,
    rows,
    onChange,
    error,
    inputOverrides,
    placeholder
  } = props;
  return (
    <TextField
      classes={{ root: `${classes.root} ${className}` }}
      onChange={e => onChange(e.target.value)}
      required={required}
      label={label}
      error={error}
      placeholder={placeholder ? placeholder : "Enter your email address"}
      multiline={multiline}
      rows={rows}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.formLabel,
          focused: classes.formLabelFocused,
          error: { borderColor: "red" }
        }
      }}
      InputProps={{
        classes: { root: `${classes.myInput} ${inputOverrides}` },
        disableUnderline: true
      }}
    />
  );
};

export default CustomField;
