import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    "& .content": {
      maxWidth: 500,
      "& h2": {
        textAlign: "left"
      },
      "& p": {
        textAlign: "left",
        lineHeight: 1.8
      }
    },
    "& .contentLeft": {
      maxWidth: 500,
      "& p": {
        lineHeight: 1.8
      }
    },
    "& img": {
      maxWidth: "20vw"
    }
  },
  charts: {
    maxWidth: "40vw !important"
  },
  styleRight: { textAlign: "left", justifyContent: "center !important" },

  "@media (max-width:900px)": {
    root: {
      "& img": {
        maxWidth: "65vw"
      }
    },
    charts: {
      maxWidth: "60vw !important"
    }
  }
}));
