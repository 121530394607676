import React from "react";
import "../App.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    height: "8vmin"
  },
  buttonRoot: {
    backgroundColor: "#7368CD",
    textTransform: "none",
    color: "white",
    marginTop: 10,
    borderColor: "#7368CD",
    borderWidth: "1.5px",
    height: 40,
    "&:hover": {
      color: "",
      backgroundColor: "#7368CD"
    }
  }
}));

const CustomButton = props => {
  const classes = useStyles();
  const { text, onClick, className, disable } = props;

  return (
    <Button
      variant="outlined"
      disableElevation
      classes={{ root: `${classes.buttonRoot} ${className}` }}
      onClick={onClick}
      disable={disable}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
