import React from "react";
import { useStyles } from "../styles/bannersection";

const BannerSection = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="content">
        <h1>It's time to simplify.</h1>
        <p>
          Time spent on administration is time not spent with the students. But
          you still want to be data driven decisions. Our solution is designed
          to help you reduce your workload, by capturing meaningful data and
          displaying it in a way that matters, to you.
        </p>
      </div>
    </div>
  );
};

export default BannerSection;
