import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid rgba(51, 51, 51, 0.15)",
    margin: "0 30px",
    padding: "30px 25px 15px 25px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    minWidth: 250,
    maxWidth: 300,
    color: "#404040",
    backgroundColor: "white",
    "& .iconContainer": {
      display: "flex",
      justifyContent: "center",
      "& .icon": {
        borderRadius: 100,
        backgroundColor: "#827dc6",
        padding: 15,
        fontSize: 32,
        width: 30,
        height: 30,
        color: "#f4feff"
      }
    },
    "& p": {
      lineHeight: 1.8,
      fontSize: 14
    }
  },
  "@media (max-width:1050px)": {
    root: { marginTop: 25 }
  },
  "@media (max-width:600px)": {}
}));
