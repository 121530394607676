import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: "65px 50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#bbb8e0"
  },
  "@media (max-width:1050px)": {
    root: { flexWrap: "wrap", padding: "55px 150px" }
  }
}));
