import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f9f9f9",
    display: "flex",
    padding: "65px 0px",
    justifyContent: "center",
    alignItems: "center",
    color: "#333",
    "& .left": {
      flexGrow: 1,
      textAlign: "center",
      "& .leftInner": {
        display: "flex",
        "& .icons": {
          width: "50%",
          marginBottom: 20,
          "& .iconContainer": {
            margin: 10,
            display: "flex",
            justifyContent: "flex-end",
            "& .icon": {
              borderRadius: 100,
              backgroundColor: "#7368CD",
              padding: 10,
              fontSize: 16,
              width: 12,
              height: 12,
              color: "#f4feff"
            }
          }
        },
        "& .content": {
          width: "50%",
          "& .contentContainer": {
            margin: 10,
            marginLeft: 5,
            display: "flex",
            alignItems: "center",
            minHeight: 32
          }
        }
      }
    },
    "& .right": {
      minWidth: "50%",
      "& .lineContainer": {
        textAlign: "left",
        margin: 10
      },
      "& .submitButton": {
        textAlign: "left",
        margin: 10
      }
    }
  },
  multiline: {
    minWidth: 520,
    margin: "0px !important"
  },
  companyField: {
    minWidth: 280
  },
  contactDetail: {
    marginRight: 25,
    minWidth: 250
  },
  inputOverrides: {
    borderColor: "transparent",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    marginTop: "20px !important"
  },
  multilineOverrides: {
    borderColor: "transparent",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    marginTop: "20px !important",
    padding: 5
  },
  "@media (max-width:900px)": {
    root: {
      flexWrap: "wrap",
      padding: "20px 0px",
      "& .left": {
        width: 300,
        "& .leftInner": {
          "& .icons": {
            width: "40%"
          }
        }
      },
      "& .right": { maxWidth: 300 }
    },
    multiline: {
      minWidth: 0,
      width: 280
    }
  }
}));
