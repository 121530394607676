import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: "wrap",
    justifyContent: "center",
    height: "100vmin",
    background:
      "linear-gradient(86.18deg, #D6728C 3.28%, rgba(107, 102, 189, 0.85) 99.84%, rgba(174, 182, 255, 0.82) 107.63%, rgba(155, 107, 255, 0.46) 107.63%);"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 10
  },
  welcome: {
    display: "flex",
    width: "100%",
    alignItems: "center"
  },
  banner: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: 40,
    marginRight: 40,
    height: "80%",
    color: "#eaecee",
    "& .left": {
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      justifyContent: "center",
      animationName: "$appear",
      animationDuration: "1.5s",
      animationTimingFunction: "linear",
      width: "50%",
      "& .titlecontainer": {
        maxWidth: 500,
        paddingLeft: 40
      },
      "& h1": {
        fontSize: "3em",
        marginBlockStart: "0.2em",
        marginBlockEnd: "0.2em"
      }
    },
    "& .right": {
      width: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",

      "& img": {
        maxWidth: 550,
        maxHeight: "100vh"
      }
    }
  },
  "@keyframes appear": {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },

  "@media (min-width:2000px)": {
    banner: {
      "& .right": {
        "& img": {
          maxWidth: 750,
          maxHeight: "100vh"
        }
      }
    }
  },
  "@media (max-width:1100px)": {
    root: {
      height: "100vh",
      marginLeft: 0,
      marginRight: 0
    },
    banner: {
      "& .left": {
        width: "100vw",
        textAlign: "center",
        "& .titlecontainer": {
          maxWidth: 500,
          paddingLeft: 0
        }
      },
      "& .right": {
        width: "100vw",
        minHeight: 0,
        "& img": {
          marginTop: 30,
          maxWidth: "35vw",
          maxHeight: "100vh"
        }
      }
    }
  },

  "@media (max-width:900px)": {
    root: {
      height: "100vh",
      marginLeft: 0,
      marginRight: 0
    },
    banner: {
      "& .left": {
        width: "100vw",
        textAlign: "center"
      },
      "& .right": {
        maxWidth: "100vw",
        minHeight: 0,
        "& img": {
          width: "55vw"
        }
      }
    }
  },
  "@media (max-width:600px)": {
    root: {
      height: "200vw",
      marginLeft: 0,
      marginRight: 0
    },
    banner: {
      "& .left": {
        width: "80vw",
        textAlign: "center"
      },
      "& .right": {
        width: "100vw",
        minHeight: 0,
        "& img": {
          marginTop: 30,
          maxWidth: "55vw",
          maxHeight: "100vh"
        }
      }
    }
  }
}));
