import React, { useState } from "react";
import CustomField from "./field";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useStyles } from "../styles/contactform";
import CustomButton from "./button";
import { useMutation } from "@apollo/react-hooks";
import CREATE_ENQUIRY from "../mutation/enquiry";

const ContactForm = props => {
  const classes = useStyles();
  const { myRef } = props;
  const [name, changeName] = useState("");
  const [company, changeCompany] = useState("");
  const [phone, changePhone] = useState("");
  const [email, changeEmail] = useState("");
  const [message, changeMessage] = useState("");
  const [submit, onSubmit] = useState(false);
  const [submitSuccess, onSubmitSuccess] = useState(false);
  const [createEnquiry] = useMutation(CREATE_ENQUIRY);

  const submitForm = (name, company, email, phone, message) => {
    if (name !== "" && (phone !== "") & (email !== "")) {
      createEnquiry({
        variables: { name, company, email, phone, message }
      });
      onSubmitSuccess(true);
    }
    onSubmit(true);
  };

  return (
    <div className={classes.root} ref={myRef}>
      <div className="left">
        <h1>Register your interest.</h1>
        <div className="leftInner">
          <div className="icons">
            <div className="iconContainer">
              <FontAwesomeIcon icon={faPhone} className="icon" />
            </div>

            <div className="iconContainer">
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
            </div>
          </div>
          <div className="content">
            <div className="contentContainer">021 351 874</div>
            <div className="contentContainer">ryan@attera.co</div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="lineContainer">
          <CustomField
            label={"Name"}
            required
            error={submit && !submitSuccess ? true : false}
            className={classes.companyField}
            inputOverrides={classes.inputOverrides}
            onChange={changeName}
            placeholder={"Enter your name"}
          />
        </div>
        <div className="lineContainer">
          <CustomField
            label={"Company"}
            className={classes.companyField}
            inputOverrides={classes.inputOverrides}
            onChange={changeCompany}
            placeholder={"Where do you work?"}
          />
        </div>
        <div className="lineContainer">
          <CustomField
            label={"Email"}
            required
            className={classes.contactDetail}
            inputOverrides={classes.inputOverrides}
            onChange={changeEmail}
            error={submit && !submitSuccess ? true : false}
            placeholder={"Email"}
          />
          <CustomField
            label={"Phone"}
            required
            className={classes.contactDetail}
            inputOverrides={classes.inputOverrides}
            onChange={changePhone}
            error={submit && !submitSuccess ? true : false}
            placeholder={"Phone Number"}
          />
        </div>
        <div className="lineContainer">
          <CustomField
            label={"Message"}
            multiline
            className={classes.multiline}
            inputOverrides={classes.multilineOverrides}
            rows={4}
            onChange={changeMessage}
            placeholder={"Is there anything else you want us to know?"}
          />
        </div>
        <div className="submitButton">
          {submitSuccess === false ? (
            <CustomButton
              text={"Submit"}
              type={"dark"}
              onClick={e => submitForm(name, company, email, phone, message)}
            />
          ) : (
            <p>Thanks! We'll be in touch over the next 24 hours.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
