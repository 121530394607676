import gql from "graphql-tag";

const CREATE_ENQUIRY = gql`
  mutation CreateEnquiry(
    $name: String
    $company: String
    $email: String
    $phone: String
    $message: String
  ) {
    createEnquiry(
      input: {
        data: {
          name: $name
          company: $company
          email: $email
          phone: $phone
          message: $message
        }
      }
    ) {
      enquiry {
        id
      }
    }
  }
`;

export default CREATE_ENQUIRY;
