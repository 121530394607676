import gql from "graphql-tag";

const CREATE_REGISTER = gql`
  mutation CreateRegister($email: String) {
    createAtteraRegister(input: { data: { email: $email } }) {
      atteraRegister {
        id
      }
    }
  }
`;

export default CREATE_REGISTER;
