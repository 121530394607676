import React from "react";
import { useStyles } from "../styles/fullsection";

const FullSection = props => {
  const classes = useStyles();
  const { type, data, image, overrides } = props;
  const left = <div className={`left ${overrides}`}>{image}</div>;
  const right = <div className={`right ${overrides}`}>{data}</div>;
  return (
    <div className={classes.root}>
      {type === "left" ? left : right}
      {type === "left" ? right : left}
    </div>
  );
};

export default FullSection;
